<template>
  <div style="width: 100%; height: 100%">
    <van-nav-bar title="视点分享管理" @click-left="onClickLeft" left-arrow />
    <!-- <van-pull-refresh
      v-model="isLoading"
      style="height: 94vh"
      @refresh="onRefresh"
    > -->
      <div v-if="PointList.length > 0" class="popDiv">
        <van-swipe-cell v-for="(item, index) in PointList" :key="index">
          <div class="flexLR pointDiv">
            <div class="flexLR" style="width: 100%">
              <div style="width: 20%">
                <img
                  :src="$store.state.baseUrl + item.vipo05"
                  style="width: 100%; height: 100%"
                  alt=""
                />
              </div>
              <div style="width: 60%">
                <div style="font-weight: bold">{{ item.vipo02 }}</div>
                <div>创建时间:{{ item.vipo09 }}</div>
                <div>分享时间:{{ item.vipo14 }}</div>
                <div>有效期:{{ computedTime(item) }}天</div>
              </div>
              <div>
                <van-switch
                  size="16px"
                  v-model="item.vipo16 == 1 ? true : false"
                  @click="checkedClick(item)"
                />
              </div>
            </div>
          </div>
          <template #right>
            <van-button
              @click="deletePoint(item)"
              square
              style="width: 100%; height: 100%"
              type="danger"
              text="删除"
            />
          </template>
        </van-swipe-cell>
      </div>
      <div v-else style="color: #0a9efc; margin-top: 100px; text-align: center">
        <img src="../../../assets/mobileImg/wu.png" width="60" alt="" />
        <div>暂无数据</div>
      </div>
    <!-- </van-pull-refresh> -->
  </div>
</template>

<script>
import {
  GetViewPointShare,
  UpdateViewPointShareState,
  DelViewPointShare,
} from "@/api";
import { mapGetters } from "vuex";
export default {
  name: "administration",
  data() {
    return {
      PointList: [],
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["qid", "pid"]),
    computedTime: () => {
      return function (v) {
        var newTime = new Date().getTime();
        var dTime = new Date(v.vipo15).getTime();
        if (dTime - newTime <= 0) {
          return "0";
        } else {
          return new Date(dTime - newTime).getDate();
        }
      };
    },
  },
  methods: {
    async deletePoint(v) {
      let res = await DelViewPointShare({ id: v.vipo01 });
      res.Code > 0 ? this.$Toast("操作成功") && this.getData() : "";
    },
    checkedClick(v) {
      if (v.vipo16 == 0) {
        v.vipo16 = 1;
      } else {
        v.vipo16 = 0;
      }
      UpdateViewPointShareState({
        ids: v.vipo01,
        time: v.vipo14,
        type: v.vipo16,
      }).then((res) => {
        res.Code > 0 ? this.$Toast("操作成功") && this.getData() : "";
      });
    },
    onRefresh() {
      setTimeout(() => {
        this.$Toast("刷新成功");
        this.isLoading = false;
        this.getData();
      }, 500);
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    async getData(id) {
      let res = await GetViewPointShare({
        params: {
          uid: this.$store.state.uid,
          modelId: this.$route.query.id,
        },
      });
      this.PointList = res.Data;
    },
  },
  mounted() {},
  created() {
    if (this.$route.query.id) {
      this.getData();
    }
  },
};
</script>


<style lang="scss" scoped>
.popDiv {
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 10px 30px;
}
.pointDiv {
  border-radius: 10px;
  min-height: 200px;
  padding: 0 40px;
  box-shadow: 0 0 8px 5px rgb(238, 238, 238);
  background-color: white;
  margin: 20px 0;
}
</style>